import { computed, ref, watchEffect } from "vue"
import { useConfigurationService } from "@maine-cat/service/configuration"
import { defineService } from "@maine-cat/common/service"
import { defaultPalette } from "./palette"
import { createPaletteVariables } from "./variables"

export type DarkMode = "light" | "dark" | "system"

export const useTheme = defineService({
  id: "theme",
  setup() {
    const { useConfig } = useConfigurationService()

    const palette = useConfig({
      id: "theme.palette",
      default: defaultPalette,
    })

    // css element
    const element = document.createElement("style")
    element.type = "text/css"

    watchEffect(cleanup => {
      document.head.appendChild(element)
      cleanup(() => {
        document.head.removeChild(element)
      })
    })

    watchEffect(() => {
      element.innerHTML = createPaletteVariables(palette.value)
    })

    // dark mode
    const supportsSystemDarkMode =
      typeof window !== "undefined" &&
      typeof window.matchMedia === "function"

    const systemDarkMode = ref(false)

    if (supportsSystemDarkMode) {
      watchEffect(cleanup => {
        const darkMode = window.matchMedia("(prefers-color-scheme: dark)")
        systemDarkMode.value = darkMode.matches

        const listener = (e: MediaQueryListEvent) => {
          systemDarkMode.value = e.matches
        }
        darkMode.addEventListener("change", listener)
        cleanup(() => darkMode.removeEventListener("change", listener))
      })
    }

    const userDarkMode = useConfig<DarkMode>({
      id: "theme.dark-mode",
      default: "system",
    })

    const darkMode = computed(() => {
      if (supportsSystemDarkMode && userDarkMode.value === "system")
        return systemDarkMode.value
      return userDarkMode.value === "dark"
    })

    watchEffect(() => {
      if (darkMode.value)
        document.documentElement.classList.add("dark")
      else
        document.documentElement.classList.remove("dark")
    })

    return { palette, supportsSystemDarkMode, systemDarkMode, userDarkMode, darkMode }
  },
})
