
export function isRangeDefined(list: any[], start: number, end: number) {
  for (let i = start; i < end; i++) {
    if (typeof list[i] === "undefined")
      return false
  }
  return true
}

export function sumRange(list: number[], start: number, end: number, defaultValue = 0) {
  let sum = 0
  for (let i = start; i < end; i++)
    sum += list[i] || defaultValue
  return sum
}

export function avgRange(list: number[], start: number, end: number) {
  let sum = 0, count = 0
  for (let i = start; i < end; i++) {
    if (typeof list[i] !== "undefined")
      sum += list[i], count += 1
  }
  return (sum / count) || 0
}

export function argAccumulate(list: number[], target: number, defaultValue = 0) {
  let sum = 0
  for (let i = 0; i < list.length; i++) {
    if (sum >= target)
      return i
    sum += list[i] || defaultValue
  }
  return list.length
}
