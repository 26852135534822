import { defineTransition } from "./defineTransition"

export const ScaleInOut = defineTransition("ScaleInOut", {
  enter: "duration-100 ease-out",
  enterFrom: "opacity-0 scale-90",
  enterTo: "opacity-100 scale-100",
  leave: "duration-75 ease-in",
  leaveFrom: "opacity-100 scale-100",
  leaveTo: "opacity-0 scale-90",
})
