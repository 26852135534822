import { defineComponent } from "vue"
import { tv } from "tailwind-variants"
import { hotkeyFromString, isOsx, osxKeyDisplay, winKeyDisplay } from "@maine-cat/service/keybinding"

const classes = tv({
  slots: {
    base: [
      "inline-block rounded px-2 shadow-1 font-mono text-sm align-middle",
      "bg-white dark:bg-gray-600 border border-gray-300 dark:border-gray-500",
    ],
    addSign: "after:content-['+'] px-1 font-mono text-gray-500",
  },
})

const Key = defineComponent({
  name: "Key",
  props: {
    k: { type: String, required: true },
    osx: null,
  },
  setup(props) {
    return () => {
      const key = props.k
      const keyDisplay = props.osx ?? isOsx ? osxKeyDisplay : winKeyDisplay
      const { base } = classes()
      return (
        <span class={base()}>
          {key in keyDisplay ? keyDisplay[key as " "] : key}
        </span>
      )
    }
  },
})

export const HotkeyHint = defineComponent({
  name: "HotkeyHint",
  props: {
    keys: String,
    highlight: String,
    osx: null,
  },
  setup(props) {
    return () => {
      if (!props.keys)
        return null
      const key = hotkeyFromString(props.keys)
      const { addSign } = classes()

      return (
        <span>
          {key.ctrl && [<Key k="ctrl" osx={props.osx} />, <span class={addSign()} />]}
          {key.alt && [<Key k="alt" osx={props.osx} />, <span class={addSign()} />]}
          {key.shift && [<Key k="shift" osx={props.osx} />, <span class={addSign()} />]}
          {key.meta && [<Key k="meta" osx={props.osx} />, <span class={addSign()} />]}
          {key.key && <Key k={key.key} />}
        </span>
      )
    }
  },
})
