import { defineComponent } from "vue"
import { tv } from "tailwind-variants"
import { Ripple, useRipple } from "../Ripple"
import { useTabs } from "./context"

const classes = tv({
  base: [
    "block px-4 py-2 relative overflow-hidden rounded-t select-none",
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4",
    "focus-visible:outline-var-500/40 dark:focus-visible:outline-var-200/40",
    "[-webkit-tap-highlight-color:transparent]",
  ],
  variants: {
    state: {
      normal: "text-gray-500 dark:text-gray-300",
      disabled: "text-gray-300 dark:text-gray-600 pointer-events-none",
      selected: "active text-var-600 dark:text-var-300",
    },
  },
})

export const Tab = defineComponent({
  name: "Tab",
  props: {
    disabled: Boolean,
    class: null,
    buttonProps: null,
    noRipple: Boolean,
    index: { type: Number, default: NaN },
  },
  setup(props, { slots }) {
    const [ripple, rippleEvents] = useRipple()
    const currentTab = useTabs()
    const onClick = () => currentTab.value = props.index
    return () => {
      const state = currentTab.value === props.index ? "selected"
        : props.disabled ? "disabled" : "normal"
      return (
        <button
          class={["Tab", classes({ state, class: props.class })]}
          onClick={onClick}
          disabled={props.disabled}
          {...props.buttonProps || {}}
          {...rippleEvents}
        >
          {!props.noRipple && <Ripple ref={ripple} rippleClass="bg-var-300" />}
          {slots.default?.()}
        </button>
      )
    }
  },
})
