import { cloneVNode, computed, defineComponent, ref, watch } from "vue"
import { tv } from "tailwind-variants"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/solid"
import { Color } from "../theme"
import { useFloating } from "../hooks/useFloating"
import { useUncontrolled } from "../hooks/useUncontrolled"
import { FilledInput } from "../Input"
import { List } from "../List"
import { ScaleInOut } from "../Transitions"
import { resolveContent } from "./resolveContent"
import { provideSelectContext } from "./context"

const classes = tv({
  slots: {
    base: "inline-block relative",
    input: "min-w-[140px] text-left [-webkit-tap-highlight-color:transparent]",
    options: [
      "absolute z-10 w-full max-h-[240px] overflow-y-auto shadow-4 rounded-sm",
      "transition origin-top [&.placement-top]:origin-bottom bg-white dark:bg-var-700",
      "focus-visible:outline-none",
    ],
    placeholder: "italic text-gray-500",
  },
  variants: {
    fullWidth: {
      true: {
        base: "w-full",
      },
    },
  },
})

export const Select = defineComponent({
  name: "Select",
  props: {
    class: null,
    inputClass: null,
    label: String,
    multiple: Boolean,
    placeholder: String,
    modelValue: null,
    defaultValue: null,
    color: String as () => Color,
    disabled: Boolean,
    error: Boolean,
    size: String as () => "normal" | "small",
    helperText: String,
    fullWidth: Boolean,
  },
  emits: {
    "update:modelValue": (e: any) => true,
  },
  setup(props, { slots, emit }) {
    const selectOptions = ref<{ $el: HTMLElement }>()
    const selectButton = ref<{ $el: HTMLElement }>()

    const value = useUncontrolled(
      props.defaultValue ?? (props.multiple ? [] : null),
      () => props.modelValue,
      value => emit("update:modelValue", value),
    )
    provideSelectContext(value, computed(() => props.multiple))

    useFloating(computed(() => selectOptions.value?.$el), {
      placement: "bottom",
      reference: computed(() => selectButton.value?.$el),
    })

    const optionsOpen = ref(false)
    const onFocusout = (e: FocusEvent) => {
      if (!document.hasFocus())
        return
      if (!(e.currentTarget as HTMLElement).contains(e.relatedTarget as HTMLElement))
        optionsOpen.value = false
    }
    watch(value, () => {
      if (!props.multiple)
        optionsOpen.value = false
    })

    const onEscKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape")
        optionsOpen.value = false
    }

    return () => {
      const hasModelValue =
        value.value !== undefined && value.value !== null &&
        !(props.multiple && value.value?.length === 0)

      const showPlaceholder = props.label ? false
        : !hasModelValue && props.placeholder

      const childrenArray = slots.default?.().flat()
        .map((node, index) => cloneVNode(node, { index })) || []

      const { base, input, options, placeholder } = classes({ fullWidth: props.fullWidth })

      return (
        <div class={["Select", base({ class: props.class })]} onFocusout={onFocusout} onKeydown={onEscKeydown}>
          <FilledInput
            is="button"
            ref={selectButton}
            focused={optionsOpen.value}
            inputClass={input({ class: props.inputClass })}
            modelValue={hasModelValue ? "true" : undefined}
            label={props.label}
            color={props.color}
            disabled={props.disabled}
            error={props.error}
            size={props.size}
            helperText={props.helperText}
            fullWidth={props.fullWidth}
            v-slots={{
              default: () => resolveContent(
                value.value,
                props.multiple,
                slots.content,
                childrenArray,
                Boolean(showPlaceholder),
                <span class={placeholder()}>{props.placeholder}</span>,
              ),
              icon: () => optionsOpen.value ? <ChevronUpIcon /> : <ChevronDownIcon />,
            }}
            onFocus={() => optionsOpen.value = true}
          />

          <ScaleInOut show={!props.disabled && optionsOpen.value}>
            <List dense={props.size === "small"} ref={selectOptions} class={["SelectOptions", options()]}>
              {childrenArray}
            </List>
          </ScaleInOut>
        </div>
      )
    }
  },
})
