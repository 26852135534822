import { EffectScope, UnwrapRef, effectScope, inject, onScopeDispose, provide, reactive, unref, watchEffect } from "vue"

// see https://github.com/vuejs/rfcs/blob/master/active-rfcs/0041-reactivity-effect-scope.md#example-a-shared-composable

export function createSharedState<T>(composable: () => T) {
  let subscribers = 0
  let state: T | undefined
  let scope: EffectScope | undefined
  const dispose = () => {
    if (scope && --subscribers <= 0) {
      scope.stop()
      state = scope = undefined
    }
  }
  return () => {
    subscribers++
    if (!scope) {
      scope = effectScope(true)
      state = scope.run(composable)
    }
    onScopeDispose(dispose)
    return state!
  }
}

export function createInjectionState<TArgs extends any[], T>(key: string | Symbol, fn: (...args: TArgs) => T) {
  function provideState(...arg: TArgs) {
    const value = fn(...arg)
    provide<T>(key, value)
    return value
  }
  function injectState() {
    const value = inject<T>(key)
    if (!value)
      throw new Error(`Unable to inject state with key "${key}"`)
    return value as T
  }
  return [provideState, injectState] as const
}

export function useAsyncValue<T>(value: MaybeRef<PromiseLike<T>>) {
  const state = reactive({
    loading: false,
    value: null as null | T,
    error: null as null | Error,
  })

  let version = 0
  watchEffect(async cleanup => {
    const v = ++version % 0xffff
    state.loading = true

    try {
      const awaited = await unref(value)
      if (version !== v)
        return
      state.value = awaited as UnwrapRef<T>
      state.error = null
      state.loading = false
    }
    catch (error) {
      if (version !== v)
        return
      state.error = error as Error
      state.loading = false
    }
  })

  return state
}
