import { useUncontrolled } from "@maine-cat/components"
import { tv } from "tailwind-variants"
import { defineComponent } from "vue"
import { HighlightRuleSet } from "./highlight"
import { HighlightLayer } from "./HighlightLayer"

const classes = tv({
  slots: {
    base: [
      "relative overflow-hidden [text-size-adjust:none] whitespace-pre-wrap break-words",
      "rounded-sm transition bg-white dark:bg-zinc-900",
      "ring-0 focus-within:ring-2 ring-blue-400/30",
      "font-mono text-lg",
    ],
    dummy: "relative p-2 pb-6 text-transparent select-none",
    highlights: "select-none",
    rule: "absolute inset-0 p-2 text-transparent overflow-hidden",
    textarea: "absolute inset-0 p-2 block outline-none bg-transparent resize-none overflow-hidden",
  },
})

export const TextEditor = defineComponent({
  name: "TextEditor",
  props: {
    class: null,
    modelValue: String,
    defaultValue: String,
    placeholder: String,
    readonly: Boolean,
    highlightRules: Array as () => HighlightRuleSet[],
  },
  setup(props, { emit }) {
    const value = useUncontrolled(
      props.defaultValue ?? "", () => props.modelValue,
      value => emit("update:modelValue", value),
    )
    const onInput = (e: Event) => value.value = (e.target as HTMLTextAreaElement).value
    return () => {
      const { base, dummy, highlights, rule, textarea } = classes()
      return (
        <div class={["TextEditor", base({ class: props.class })]}>
          <div class={["DummyContent", dummy()]}>
            {value.value}{"\u200B" /* zero width space */}
          </div>
          <div class={["Highlights", highlights()]}>
            {props.highlightRules?.map(rules =>
              <div key={rules.owner} class={rule()}>
                <HighlightLayer input={value.value} rules={rules} />
              </div>)}
          </div>
          <textarea
            class={textarea()}
            autocomplete="off"
            maxlength="800"
            readonly={props.readonly}
            placeholder={props.placeholder}
            value={value.value}
            onInput={onInput}
          />
        </div>
      )
    }
  },
})
