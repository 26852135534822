import { computed, ref } from "vue"

export function useUncontrolled<T>(init: T, getter: () => T | undefined, setter: (value: T) => void) {
  const uncontrolled = ref(init)

  const value = computed<T>({
    get: () => {
      const controlled = getter()
      return controlled === undefined ? uncontrolled.value as T : controlled
    },
    set: value => {
      setter(value)
      uncontrolled.value = value as any
    },
  })

  return value
}
