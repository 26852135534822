import { unref, watchEffect } from "vue"
import {
  Placement, ReferenceElement, Strategy, arrow,
  autoUpdate, computePosition, flip, offset, shift,
} from "@floating-ui/dom"

export interface FloatingOptions {
  reference?: MaybeRef<ReferenceElement | undefined>
  arrow?: MaybeRef<HTMLElement | undefined>
  offset?: number
  placement?: Placement
  watchAnimationFrame?: true
  strategy?: Strategy
}

export function useFloating(
  popper: MaybeRef<HTMLElement | undefined>,
  options: FloatingOptions = {},
) {

  watchEffect(cleanup => {
    const popperEl = unref(popper)
    if (!popperEl)
      return
    const referenceEl = unref(options.reference) ?? popperEl.parentElement!

    const middleware = [shift(), flip()]

    if (options.offset !== undefined)
      middleware.push(offset(options.offset))

    const arrowEl = unref(options.arrow)
    if (arrowEl)
      middleware.push(arrow({ element: arrowEl }))

    let lastPlace = "placement-"
    const update = async () => {
      const {
        x, y, strategy, placement, middlewareData,
      } = await computePosition(referenceEl, popperEl, {
        middleware, placement: options.placement, strategy: options.strategy,
      })
      const newPlace = "placement-" + placement
      popperEl.classList.remove(lastPlace)
      popperEl.classList.add(newPlace)
      lastPlace = newPlace
      Object.assign(popperEl.style, { left: `${x}px`, top: `${y}px`, position: strategy })

      if (arrowEl && middlewareData.arrow) {
        const { x, y } = middlewareData.arrow
        Object.assign(arrowEl.style, arrowStyle(x, y, placement))
      }
    }

    cleanup(autoUpdate(referenceEl, popperEl, update, { animationFrame: options.watchAnimationFrame }))
  })
}

function arrowStyle(x: number | undefined, y: number | undefined, placement: string) {
  const style: any = {}
  if (x) {
    style.left = `${x}px`
    if (placement.includes("top"))
      style.bottom = "0px"
    else
      style.top = "0px"
  }
  else if (y) {
    style.top = `${y}px`
    if (placement.includes("left"))
      style.right = "0px"
    else
      style.left = "0px"
  }
  return style
}
