import { defineComponent, ref } from "vue"
import { tv } from "tailwind-variants"
import { hotkeyFromEvent, hotkeyFromString } from "@maine-cat/service/keybinding"
import { HotkeyHint } from "./HotkeyHint"
import css from "./index.module.css"

const classes = tv({
  slots: {
    base: "flex flex-col items-center space-y-2",
    input: [
      "relative rounded text-center w-32 outline-none p-2",
      "select-none cursor-text font-mono",
      "transition bg-gray-200/60 hover:bg-gray-200",
      "border-black/40 hover:border-black/60 text-gray-500",
      "dark:bg-gray-700/60 dark:hover:bg-gray-700/80",
      "dark:border-var-100/40 dark:hover:border-var-100/60",
    ],
    caret: `${css.caret} text-gray-700 dark:text-gray-200`,
  },
})

export const HotkeyInput = defineComponent({
  name: "HotkeyInput",
  props: {
    class: null,
    defaultValue: null,
    placeholder: null,
  },
  emits: {
    commit: (key: string | undefined) => true,
    cancel: () => true,
  },
  setup(props, { emit }) {
    const keys = ref<string | undefined>(props.defaultValue)
    const onKeydown = (e: KeyboardEvent) => {
      (e.target as HTMLInputElement).value = ""
      e.preventDefault()
      e.stopPropagation()
      const keyStr = hotkeyFromEvent(e, false)
      const key = keyStr && hotkeyFromString(keyStr)
      if (keyStr && key) {
        if (key.key === "Escape") {
          if (keys.value)
            keys.value = undefined
          else
            emit("cancel")
        }
        else if (key.key === "Enter") {
          emit("commit", keys.value)
        }
        else if (key.key === "Process") {
          keys.value = undefined
        }
        else {
          keys.value = keyStr
        }
      }
    }
    const onKeyup = (e: KeyboardEvent) => {
      if (keys.value && !hotkeyFromString(keys.value).key) {
        const key = hotkeyFromEvent(e, false)
        if (key) keys.value = key
        else keys.value = undefined
      }
    }
    return () => {
      const { base, input, caret } = classes()
      return (
        <div class={["HotkeyInput", base({ class: props.class })]}>
          <div
            tabindex={0}
            class={input()}
            onKeydown={onKeydown}
            onKeyup={onKeyup}>
            <span>{props.placeholder}</span>
            <span class={caret()}>|</span>
          </div>
          <HotkeyHint keys={keys.value} />
        </div>
      )
    }
  },
})
