import { FocusTrap, createFocusTrap } from "focus-trap"
import { ref, unref, watch } from "vue"

function unrefEl(ref: MaybeRef<any>) {
  const value = unref(ref)
  return value?.$el || value
}

export interface UseFocusTrapOptions {
  returnFocus?: MaybeRef<boolean>
  onDeactivate?: () => void
  onPostDeactivate?: () => void
}

export function useFocusTrap(el: MaybeRef<any>, options: UseFocusTrapOptions = {}) {

  let trap: FocusTrap | undefined

  const hasTrap = ref(false)
  const isPaused = ref(false)

  const pause = () => {
    if (trap) {
      trap.pause()
      isPaused.value = true
    }
  }

  const unpause = () => {
    if (trap) {
      trap.unpause()
      isPaused.value = false
    }
  }

  watch(() => unrefEl(el), (el, _, cleanup) => {
    if (!el) return

    const _trap = trap = createFocusTrap(el, {
      onActivate() {
        hasTrap.value = true
      },
      onDeactivate() {
        hasTrap.value = false
      },
    })

    _trap.activate()
    cleanup(() => {
      trap = undefined
      const { returnFocus, ...others } = options
      _trap.deactivate({
        returnFocus: unref(returnFocus) || true,
        ...others,
      })
    })
  }, { flush: "post" })

  return { hasTrap, isPaused, pause, unpause }
}
