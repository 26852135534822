import { defineComponent } from "vue"
import { HighlightRuleSet, resolveHighlight } from "./highlight"

export const HighlightLayer = defineComponent({
  name: "HighlightLayer",
  props: {
    rules: { type: Object as () => HighlightRuleSet, required: true },
    input: { type: String, required: true },
  },
  setup(props) {
    // TODO: hover
    return () => <>
      {resolveHighlight(props.input, props.rules).map(x =>
        x.rule
          ? <span class={x.rule.decoration}>{x.text}</span>
          : x.text)}
    </>
  },
})
