import { Transition, defineComponent } from "vue"

export interface TransitionProps {
  enter?: string
  enterFrom?: string
  enterTo?: string
  entered?: string
  leave?: string
  leaveFrom?: string
  leaveTo?: string
}

export function defineTransition(name: string, transition: TransitionProps) {
  return defineComponent({
    name,
    props: {
      show: Boolean,
      appear: Boolean,
    },
    setup(props, { slots }) {
      return () => {
        const { enter, enterFrom, enterTo, entered, leave, leaveFrom, leaveTo } = transition
        return (
          <Transition
            appear={props.appear}
            enterActiveClass={enter} enterFromClass={enterFrom} enterToClass={enterTo}
            appearActiveClass={entered}
            leaveActiveClass={leave} leaveFromClass={leaveFrom} leaveToClass={leaveTo}>
            {props.show && slots.default?.()}
          </Transition>
        )
      }
    },
  })
}
