import { defineComponent } from "vue"
import { VirtualScrollItem } from "./VirtualScrollItem"

export const VirtualScrollList = defineComponent({
  name: "VirtualScrollList",
  props: {
    list: { type: Array as () => any[], required: true },
    state: {
      type: Object as () => ({ start: number, end: number, top: number, content: number, avg: number }),
      required: true,
    },
    heights: { type: Array as () => number[], required: true },
    keyProp: { type: [String, Function], required: true },
    renderComponent: { type: [Object, Function], required: true },
    reportHeight: {
      type: Function as any as () => (i: number, h: number) => void,
      required: true,
    },
  },
  setup(props) {
    return () => {
      const { list, state, heights, keyProp, renderComponent, reportHeight } = props
      const { start, end, top, avg } = state
      const result: any[] = []

      // read content height for deps
      state.content

      const getKey = (item: any) =>
        typeof keyProp === "string"
          ? item[keyProp] : keyProp(item)

      let h: number | undefined = top
      for (let i = start; i < end && i < list.length; i++) {
        const item = list[i]
        result.push(<VirtualScrollItem
          key={getKey(item)}
          item={item}
          index={i}
          top={h}
          reportHeight={reportHeight}
          renderComponent={renderComponent}
        />)
        // use history heights or fallback to average
        h += typeof heights[i] === "number" ? heights[i] : avg
      }
      return result
    }
  },
})
