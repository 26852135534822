import { defineComponent, ref } from "vue"
import { tv } from "tailwind-variants"
import { useUncontrolled } from "../hooks/useUncontrolled"
import { Color } from "../theme"

const classes = tv({
  slots: {
    wrapper: "inline-block",
    base: [
      "flex relative group",
      "after:absolute after:inset-x-0 after:bottom-0",
      "after:transition after:duration-200",
    ],
    label: "absolute origin-top-left transition duration-150 z-1 left-o top-0",
    input: "transition duration-150 [.focused_&]:outline-none rounded-t border-b",
    helper: "transition duration-150 text-sm mx-4 mt-[0.2rem]",
    icon: "absolute pointer-events-none right-2 top-[calc(50%-.5em)] w-[1em] h-[1em]",
  },
  variants: {
    status: {
      normal: {
        base: [
          "after:border-b-2 after:border-var-600 after:scale-x-0 after:[.focused_&]:scale-x-100",
          "dark:after:border-var-300",
        ],
        label: [
          "text-gray-500 [.focused_&]:text-var-600",
          "dark:text-gray-400 dark:[.focused_&]:text-var-300",
        ],
        helper: "text-gray-500/90 dark:text-gray-400/90",
      },
      disabled: {
        base: "after:border-0",
        label: "text-gray-400 dark:text-gray-500",
        helper: "text-gray-500/90 dark:text-gray-500/90",
      },
      error: {
        base: "after:border-b-2 after:border-var-400 after:scale-x-100",
        label: "text-var-400",
        helper: "text-var-400",
      },
    },
    label: {
      true: {
        input: [
          "px-4 pt-6 pb-2",
          "placeholder:transition placeholder:select-none placeholder:duration-150",
          "placeholder:text-transparent [.focused_&]:placeholder:text-gray-500",
          "dark:[.focused_&]:placeholder:text-gray-400",
        ],
      },
      false: {
        input: "px-4 pt-3 pb-3 placeholder:text-gray-500",
      },
    },
    disabled: {
      true: {
        input: [
          "border-dashed bg-gray-300/60 border-black/40 text-gray-600",
          "dark:bg-gray-600/60 dark:border-var-100/40 dark:text-gray-400",
        ],
      },
      false: {
        input: [
          "bg-gray-200/60 hover:bg-gray-200 [.focused_&]:bg-gray-200/60",
          "border-black/40 hover:border-black/60  text-gray-900",
          "dark:bg-gray-700/60 dark:hover:bg-gray-700/80 dark:[.focused_&]:bg-gray-700/60",
          "dark:border-var-100/40 dark:hover:border-var-100/60 dark:text-gray-50",
        ],
      },
    },
    shrinking: {
      true: {},
    },
    focusing: {
      true: {
        wrapper: "focused",
      },
    },
    size: {
      normal: {},
      small: {},
    },
    fullWidth: {
      true: {
        base: "w-full",
        wrapper: "w-full",
        input: "w-full",
      },
    },
  },
  compoundVariants: [
    {
      shrinking: false, size: "normal",
      class: { label: "[transform:translate(1rem,1rem)_scale(1)] pointer-events-none" },
    },
    {
      shrinking: false, size: "small",
      class: { label: "[transform:translate(1rem,0.8rem)_scale(1)] pointer-events-none" },
    },
    {
      shrinking: true, size: "normal",
      class: { label: "[transform:translate(1rem,0.3rem)_scale(.75)] select-none" },
    },
    {
      shrinking: true, size: "small",
      class: { label: "[transform:translate(1rem,0.1rem)_scale(.75)] select-none" },
    },
    {
      size: "small", label: true,
      class: { input: "px-4 pt-5 pb-1" },
    },
    {
      size: "small", label: false,
      class: { input: "px-4 pt-2 pb-2" },
    },
  ],
})

export const FilledInput = defineComponent({
  name: "FilledInput",
  inheritAttrs: false,
  props: {
    class: null,
    wrapperClass: null,
    inputClass: null,
    type: String,
    label: String,
    modelValue: String,
    defaultValue: String,
    placeholder: String,
    disabled: Boolean,
    error: Boolean,
    helperText: String,
    fullWidth: Boolean,
    focused: Boolean,
    is: {
      type: null,
      default: "input",
    },
    color: {
      type: String as () => Color,
      default: "primary",
    },
    size: {
      type: String as () => "normal" | "small",
      default: "normal",
    },
  },
  emits: {
    click: (e: MouseEvent) => true,
    focus: (e: FocusEvent) => true,
    blur: (e: FocusEvent) => true,
    keydown: (e: KeyboardEvent) => true,
    "update:modelValue": (e: string) => true,
  },
  setup(props, { slots, attrs, emit, expose }) {
    const el = ref<HTMLElement>()
    const rootEl = ref<HTMLElement>()
    expose({ el, $el: el, rootEl })

    const value = useUncontrolled(
      props.defaultValue ?? "", () => props.modelValue,
      value => emit("update:modelValue", value),
    )

    const realFocusing = ref(false)

    return () => {

      const {
        type, is, helperText, placeholder, label,
        size, color, error, disabled, focused, fullWidth,
      } = props
      const status = disabled ? "disabled" : error ? "error" : "normal"
      const focusing = realFocusing.value || focused
      const shrinking = !!(value.value || focusing)

      const cls = classes({ label: !!label, size, status, shrinking, disabled, fullWidth, focusing })

      const Component = is ?? "input"

      return (
        <div ref={rootEl} class={["Input", `color-${error ? "error" : color}`, cls.wrapper({ class: props.wrapperClass })]}>
          <label class={cls.base({ class: props.class })}>
            {label &&
              <span class={cls.label()}>
                {label}
              </span>}

            <Component
              {...attrs}
              ref={el}
              value={value.value}
              onInput={(e: InputEvent) => value.value = (e.target as any).value}
              type={type ?? (is === "button" ? "button" : "text")}
              placeholder={placeholder}
              disabled={disabled}
              class={cls.input({ class: props.inputClass })}
              onFocus={(e: FocusEvent) => { realFocusing.value = true; emit("focus", e) }}
              onBlur={(e: FocusEvent) => { realFocusing.value = false; emit("blur", e) }}
              onClick={(e: MouseEvent) => emit("click", e)}
              onKeydown={(e: KeyboardEvent) => emit("keydown", e)}
            >
              {slots.default?.()}
            </Component>

            {slots.icon &&
              <span class={cls.icon()}>
                {slots.icon?.()}
              </span>}
          </label>

          {helperText &&
            <p class={cls.helper()}>
              {helperText}
            </p>}
        </div>
      )
    }
  },
})
