import { defineComponent, ref } from "vue"
import { tv } from "tailwind-variants"

const classes = tv({
  base: "relative",
  variants: {
    disablePadding: {
      false: "py-2",
    },
    dense: {
      true: "list-dense",
    },
  },
})

export const List = defineComponent({
  name: "List",
  props: {
    is: null,
    class: null,
    dense: Boolean,
    disablePadding: Boolean,
  },
  setup(props, { expose, slots }) {
    const el = ref<HTMLElement>()
    expose({ el, $el: el })

    return () => {
      const Component = props.is ?? "ul"
      return (
        <Component
          ref={el}
          class={["List", props.dense && "list-dense", classes({ class: props.class })]}
        >
          {slots.default?.()}
        </Component>
      )
    }
  },
})
