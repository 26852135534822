import { defineComponent, normalizeClass, ref, withKeys } from "vue"
import { tv } from "tailwind-variants"
import css from "./index.module.css"

const classes = tv({
  slots: {
    base: ["absolute inset-0 pointer-events-none z-0", css.rippleContainer],
    ripple: [
      "absolute rounded-full opacity-30 transition duration-[400ms] ease-in-out",
      "bg-var-500 dark:bg-var-200/50",
    ],
  },
})

export function useRipple() {
  const ripple = ref<{
    start: (event: PointerEvent | KeyboardEvent) => void
    stop: (event: PointerEvent | KeyboardEvent) => void
  }>()

  const start = (e: PointerEvent | KeyboardEvent) => ripple.value?.start(e)
  const stop = (e: PointerEvent | KeyboardEvent) => ripple.value?.stop(e)

  const eventHandlers = {
    onKeydown: withKeys(start, ["space"]),
    onKeyup: withKeys(stop, ["space"]),
    onPointerdown: start,
    onPointerup: stop,
    onPointercancel: stop,
    onPointerleave: stop,
  }

  return [ripple, eventHandlers] as const
}

export const Ripple = defineComponent({
  name: "Ripple",
  props: {
    class: null,
    rippleClass: null,
    center: Boolean,
  },
  setup(props, { expose }) {
    const containerRef = ref<HTMLElement>()
    expose({ start, stop, $el: containerRef })

    const { base, ripple: rippleClass } = classes()

    let isKeyDown = false
    function start(event: PointerEvent | KeyboardEvent) {
      const el = containerRef.value
      if (!el) return
      if ("key" in event && isKeyDown) return
      const rect = el.getBoundingClientRect()
      isKeyDown = true
      const cx = props.center || "key" in event ? rect.width / 2 : event.clientX - rect.left
      const cy = props.center || "key" in event ? rect.height / 2 : event.clientY - rect.top
      const r = Math.sqrt(Math.max(rect.width - cx, cx) ** 2 + Math.max(rect.height - cy, cy) ** 2)
      const x = cx - r, y = cy - r, w = r * 2

      const ripple = document.createElement("span")
      ripple.className = normalizeClass(["ripple", rippleClass({ class: props.rippleClass })])
      Object.assign(ripple.style, {
        left: `${x}px`, top: `${y}px`, width: `${w}px`, height: `${w}px`,
      })
      el.appendChild(ripple)
    }

    function stop(event: PointerEvent | KeyboardEvent) {
      if ("key" in event && isKeyDown)
        isKeyDown = false
      const el = containerRef.value
      if (!el) return
      el.querySelectorAll<HTMLSpanElement>(".ripple").forEach(ripple => {
        ripple.classList.add("!opacity-0")
        ripple.addEventListener("transitionend", () => {
          ripple.remove()
        })
      })
    }

    return () =>
      <span
        ref={containerRef}
        class={["Ripple", base({ class: props.class })]}
      />
  },
})
