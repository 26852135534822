import { cloneVNode, defineComponent, nextTick, ref, watchEffect } from "vue"
import { tv } from "tailwind-variants"
import { useUncontrolled } from "../hooks/useUncontrolled"
import { Color } from "../theme"
import { provideTabs } from "./context"

const classes = tv({
  slots: {
    base: "flex relative",
    indicator: [
      "absolute bottom-[2px] h-[3px] rounded-full transition-all",
      "bg-var-600/80 dark:bg-var-300/80 duration-150",
    ],
    separator: "border-l border-gray-200 dark:border-gray-500 my-2",
  },
})

const indicatorOffset = 8

export const Tabs = defineComponent({
  name: "Tabs",
  props: {
    class: null,
    modelValue: Number,
    defaultValue: Number,
    color: {
      type: String as () => Color,
      default: "primary",
    },
  },
  emits: {
    "update:modelValue": (e: number) => true,
  },
  setup(props, { emit, slots }) {

    const selected = useUncontrolled(
      props.defaultValue || 0, () => props.modelValue,
      v => emit("update:modelValue", v)
    )

    provideTabs(selected)

    const listEl = ref<HTMLElement>()
    const indicatorEl = ref<HTMLSpanElement>()

    function resize() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _idx = selected.value
      const list = listEl.value
      const indicator = indicatorEl.value
      if (!list || !indicator) return
      nextTick(() => {
        const tab = list.querySelector(".Tab.active") as HTMLElement
        if (!tab) return
        Object.assign(indicator.style, {
          left: tab.offsetLeft + indicatorOffset + "px",
          width: tab.offsetWidth - indicatorOffset * 2 + "px",
        })
      })
    }

    const observer = new ResizeObserver(resize)
    watchEffect(cleanup => {
      const list = listEl.value
      if (!list) return
      observer.observe(list)
      cleanup(() => observer.unobserve(list))
    })

    watchEffect(resize, { flush: "post" })

    return () => {
      const { base, indicator, separator } = classes()
      return (
        <div ref={listEl} class={["Tabs", `color-${props.color}`, base({ class: props.class })]}>
          {slots.default?.().flat()
            .map((tab, index) => cloneVNode(tab, { index }))
            .flatMap((node, idx) => idx > 0
              ? [<div key={`separator-${idx}`} class={separator()} />, node]
              : [node])}
          < span ref={indicatorEl} class={indicator()} />
        </div >
      )
    }
  },
})
