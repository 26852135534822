
export function memo<T>(create: () => T): () => T {
  let value: T | undefined
  let created = false
  return () => {
    if (!created) {
      value = create()
      created = true
    }
    return value!
  }
}

export function delay(ms: number) {
  return new Promise(res => setTimeout(res, ms))
}

export function arrayEqual<T>(a: readonly T[], b: readonly T[]) {
  return a.length === b.length && a.every((v, i) => v === b[i])
}

export function allIndicesOf(str: string, search: string) {
  const result: number[] = []
  let i = -1
  while ((i = str.indexOf(search, i + 1)) >= 0 && i < str.length)
    result[result.length] = i
  return result
}

/**
 * Immutable Array.prototype.splice.
 * Returns the array after splice.
 * Delete elements omitted.
 */
export function splice<T>(arr: T[], start: number, deleteCount: number = 0, ...items: T[]) {
  arr = arr.slice()
  arr.splice(start, deleteCount, ...items)
  return arr
}

export function toHumanSize(bytes: number) {
  if (bytes < 1024) return `${bytes} B`
  if (bytes < 1024 ** 2) return `${(bytes / 1024) | 0} KB`
  if (bytes < 1024 ** 3) return `${(bytes / 1024 ** 2).toFixed(2)} MB`
  return `${(bytes / 1024 ** 3).toFixed(2)} GB`
}
