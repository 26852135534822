import { defineComponent, withKeys } from "vue"
import { tv } from "tailwind-variants"
import { Ripple, useRipple } from "../Ripple"
import { Color } from "../theme"

const classes = tv({
  base: [
    "flex items-center justify-start relative box-border text-left transition",
    "[-webkit-tap-highlight-color:transparent]",
  ],
  variants: {
    disablePadding: {
      false: "px-4 py-2 [.list-dense>&]:py-1",
    },
    button: {
      true: [
        "relative overflow-hidden focus-visible:outline-none",
        "cursor-pointer hover:bg-gray-200/60 dark:hover:bg-white/10",
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4",
        "focus-visible:outline-var-500/40 dark:focus-visible:outline-var-200/40",
      ],
    },
    active: {
      true: "bg-var-400/10 dark:bg-var-300/20 hover:!bg-var-400/20 dark:hover:!bg-var-300/30",
    },
  },
})

export const ListItem = defineComponent({
  name: "ListItem",
  props: {
    is: null,
    class: null,
    button: Boolean,
    active: Boolean,
    color: { type: String as () => Color, default: "primary" },
    disablePadding: Boolean,
    tabindex: null,
  },
  emits: {
    click: (e: MouseEvent) => true,
  },
  setup(props, { slots, emit }) {
    const [ripple, rippleEvents] = useRipple()

    const onKeypress = withKeys((e: KeyboardEvent) => {
      if (props.button) {
        (e.currentTarget as HTMLElement).click()
        e.stopPropagation()
        e.preventDefault()
      }
    }, ["space"])

    return () => {
      const Component = props.is ?? "li"
      return (
        <Component
          class={["ListItem", `color-${props.color}`, classes(props)]}
          tabindex={props.tabindex ?? (props.button ? 0 : undefined)}
          onClick={(e: MouseEvent) => props.button && emit("click", e)}
          onKeypress={onKeypress}
          {...rippleEvents}
        >
          {props.button &&
            <Ripple ref={ripple} rippleClass="bg-var-300 dark:bg-var-200/50" />}
          {slots.default?.()}
        </Component>
      )
    }
  },
})
