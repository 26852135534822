import { defineComponent, withModifiers } from "vue"
import { tv } from "tailwind-variants"
import { Color } from "../theme"
import { useUncontrolled } from "../hooks/useUncontrolled"
import { Ripple, useRipple } from "../Ripple"

const classes = tv({
  slots: {
    base: "inline-flex items-center group",
    switch: [
      "inline-block relative focus-visible:outline-none rounded",
      "[-webkit-tap-highlight-color:transparent]",
    ],
    label: "grow",
    track: "transition duration-200 rounded-full pointer-events-none",
    cap: [
      "absolute transition ease-in-out duration-200 flex items-center justify-center",
      "pointer-events-none rounded-full overflow-hidden",
    ],
    capButton: "transition duration-150 rounded-full shadow-2 z-0",
  },
  variants: {
    disabled: {
      false: {
        base: "cursor-pointer",
      },
      true: {
        switch: "pointer-events-none",
        label: "text-gray-400 dark:text-gray-600",
      },
    },
    labelPlacement: {
      left: {
        base: "flex-row-reverse",
      },
      right: {
        base: "flex-row",
      },
      top: {
        base: "flex-col-reverse",
      },
      bottom: {
        base: "flex-col",
      },
    },
    size: {
      small: {
        track: "w-5 h-2.5 mx-4 my-2",
        cap: "h-6 w-6 left-1 top-[1px]",
        capButton: "w-4 h-4",
      },
      normal: {
        track: "w-8 h-3.5 mx-4 my-3",
        cap: "h-9 w-9 left-1 top-[1px]",
        capButton: "w-5 h-5",
      },
    },
    checked: { true: {} },
    fullWidth: {
      true: {
        base: "w-full",
      },
    },
  },
  compoundVariants: [
    {
      disabled: false, checked: false,
      class: {
        track: "bg-gray-400/80 dark:bg-gray-400/60",
        cap: [
          "group-hover:bg-gray-500/20 [*:focus-visible_&]:bg-gray-500/20",
          "dark:group-hover:bg-gray-200/20 dark:[*:focus-visible_&]:bg-gray-200/20",
        ],
        capButton: "bg-white dark:bg-gray-200",
      },
    },
    {
      disabled: false, checked: true,
      class: {
        track: "bg-var-400/60 dark:bg-var-300/60",
        cap: "translate-x-5 group-hover:bg-var-300/30 [*:focus-visible_&]:bg-var-300/30",
        capButton: "bg-var-600 dark:bg-var-300",
      },
    },
    {
      disabled: true, checked: false,
      class: {
        track: "bg-gray-300/80 dark:bg-gray-600/60",
        cap: "",
        capButton: "bg-gray-200 dark:bg-gray-600",
      },
    },
    {
      disabled: true, checked: true,
      class: {
        track: "bg-var-200/40 dark:bg-var-600/40",
        cap: "translate-x-5",
        capButton: "bg-var-200 dark:bg-var-800",
      },
    },
  ],
})

export const Switch = defineComponent({
  name: "Switch",
  props: {
    modelValue: null,
    class: null,
    defaultValue: Boolean,
    color: {
      type: String as () => Color,
      default: "primary",
    },
    size: {
      type: String as () => "normal" | "small",
      default: "normal",
    },
    label: String,
    labelPlacement: {
      type: String as () => "left" | "right" | "top" | "bottom",
      default: "right",
    },
    disabled: Boolean,
    fullWidth: Boolean,
  },
  emits: {
    "update:modelValue": (e: boolean) => true,
  },
  setup(props, { slots, emit }) {
    const value = useUncontrolled(
      props.defaultValue, () => props.modelValue,
      value => emit("update:modelValue", value),
    )

    const [ripple, rippleEvents] = useRipple()

    const onClick = withModifiers((e: MouseEvent) => {
      value.value = !value.value
    }, ["stop", "prevent"])

    return () => {
      const { disabled, labelPlacement, label, size, fullWidth, color } = props
      const {
        base, switch: switchClass, label: labelClass, track, cap, capButton,
      } = classes({ disabled, labelPlacement, size, checked: value.value, fullWidth })

      return (
        <label class={["Switch", `color-${color}`, base({ class: props.class })]}>
          <button
            disabled={disabled}
            class={switchClass()}
            {...rippleEvents}
            onClick={onClick}
          >
            <div class={track()} />
            <div class={cap()}>
              <Ripple ref={ripple} center />
              <div class={capButton()} />
            </div>
          </button>

          <span class={labelClass()}>
            {slots.default?.() ?? label}
          </span>
        </label>
      )
    }
  },
})
