import { defineComponent, h, ref, watchEffect } from "vue"

export const VirtualScrollItem = defineComponent({
  name: "VirtualScrollItem",
  props: {
    item: Object,
    index: { type: Number, required: true },
    top: Number,
    reportHeight: {
      type: Function as any as () => (i: number, h: number) => void,
      required: true,
    },
    renderComponent: { type: [Object, Function], required: true },
  },
  setup(props) {
    const root = ref<HTMLDivElement>()

    watchEffect(cleanup => {
      const el = root.value
      const { reportHeight, index } = props
      if (!el) return
      // report in next macro task to avoid dep tracking and infinite loop
      const report = (h: number) => setTimeout(() => reportHeight(index, h))
      report(el.clientHeight)
      const observer = new ResizeObserver(entries =>
        report(entries[0].contentRect.height))
      observer.observe(el)
      cleanup(() => {
        report(0) // 0 for report removed
        observer.unobserve(el)
      })
    })

    watchEffect(() => {
      if (root.value)
        root.value.style.transform = typeof props.top === "number" ? `translateY(${props.top}px)` : ""
    })

    return () =>
      <div
        ref={root}
        data-index={props.index}
        class="VirtualScrollItem w-full absolute top-0">
        {h(props.renderComponent as any, { item: props.item, index: props.index })}
      </div>
  },
})
