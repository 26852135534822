import { Ref, UnwrapRef, ref, watch } from "vue"
import { defineService } from "@maine-cat/common/service"
import { useConfigurationStore } from "./store"

export interface ConfigurationEntry<T> {
  /**
   * Unique id, may include section name
   */
  id: string

  /**
   * Default value
   */
  default: T

  /**
   * Callback when value initialized from store
   */
  initiated?: (value: T) => void

  /**
   * If should be displayed to users. Should have an associated entry in i18n.
   */
  display?: boolean

  /**
   * Validation
   */
  validation?: {
    integer?: boolean
    range?: [number, number]

    enums?: T[]
    valueDescriptions?: string[]

    validate?: (value: unknown) => boolean | string
  }
}

export const useConfigurationService = defineService({
  id: "configuration",
  setup() {
    const { store } = useConfigurationStore()

    const configurations = new Map<string, { value: Ref<unknown>, entry: ConfigurationEntry<unknown> }>()

    function useConfig<T>(entry: ConfigurationEntry<T>): Ref<UnwrapRef<T>> {
      const { id } = entry
      if (configurations.has(id)) {
        if (import.meta.hot)
          return configurations.get(id)!.value as any
        throw new Error(`Duplicated definition of configuration "${id}"`)
      }

      const value = ref<T>(entry.default)
      configurations.set(id, { value, entry } as any)

      store.get<UnwrapRef<T>>(id).then(v => {
        if (v !== null && v !== undefined) {
          value.value = v
          entry.initiated?.(v as any)
        }
      })

      watch(value, v => {
        if (v === entry.default) {
          store.delete(id)
          return
        }
        store.set(id, v)
      })
      return value
    }

    return { configurations, useConfig }
  },
})
