import { defineComponent } from "vue"
import { tv } from "tailwind-variants"

const mapping = {
  display1: "h1",
  display2: "h2",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  body1: "p",
  body2: "p",
}

const classes = tv({
  variants: {
    is: {
      display1: "text-5xl font-normal",
      display2: "text-4xl font-normal",
      h1: "text-3xl font-medium",
      h2: "text-2xl font-medium",
      h3: "text-xl font-medium",
      h4: "text-lg font-medium",
      body1: "text-base font-normal",
      body2: "text-sm font-light",
    },
    center: { true: "text-center" },
    right: { true: "text-right" },
    justify: { true: "text-justify" },
    italic: { true: "italic" },
    bold: { true: "font-bold" },
    silent: {
      true: "text-var-600 dark:text-var-200",
      false: "text-var-800 dark:text-var-50",
    },
  },
})

export const Typography = defineComponent({
  name: "Typography",
  props: {
    is: String as () => keyof typeof mapping,
    class: null,
    center: Boolean,
    right: Boolean,
    justify: Boolean,
    italic: Boolean,
    bold: Boolean,
    silent: Boolean,
  },
  setup(props, { slots }) {
    return () => {
      const Component = (mapping[props.is ?? "body1"] ?? "p") as "p"
      return (
        <Component class={["Typography", classes(props)]}>
          {slots.default?.()}
        </Component>
      )
    }
  },
})
