import { defineComponent, markRaw, nextTick, reactive, ref, watchEffect } from "vue"
import { avgRange, isRangeDefined } from "./utils"
import { VirtualScrollList } from "./VirtualScrollList"

export const VirtualScroll = defineComponent({
  name: "VirtualScroll",
  props: {
    class: null,
    list: { type: Array as () => any[], required: true },
    keyProp: { type: [String, Function], default: "key" },
    // A Vue component with props: { item, index }
    renderComponent: { type: [Object, Function], required: true },
    extraRenderElements: { type: Number, default: 5 },
    extraRenderHeight: { type: Number, default: 200 },
  },
  setup(props, { expose }) {
    const container = ref<HTMLDivElement>()
    const panel = ref<HTMLDivElement>()

    const itemHeights: number[] = markRaw([])
    const historyHeights: number[] = markRaw([])

    const state = reactive({
      start: 0,
      end: 0,
      top: 0,
      height: 0,
      content: 0,
      avg: 0,
    })

    const onScroll = () => {
      const el = container.value
      if (!el) return

      const { extraRenderElements, extraRenderHeight } = props
      const { start, end, avg } = state
      // return if not all rendered items are mounted
      if (!isRangeDefined(itemHeights, start, end)) return
      const height = el.clientHeight
      const { scrollTop } = el

      // decide the range to render by estimating from history heights
      // using previous start and end value
      const avgTopHeight = avg || extraRenderHeight
      const startTarget = Math.floor((scrollTop - extraRenderHeight) / avgTopHeight)
      if (state.start < startTarget - extraRenderElements)
        state.start = Math.min(props.list.length, startTarget - extraRenderElements)
      else if (state.start > startTarget)
        state.start = Math.max(0, startTarget)

      const avgContentHeight = avgRange(historyHeights, start, end) || avgTopHeight
      const endTarget = startTarget + Math.ceil((height + 2 * extraRenderHeight) / avgContentHeight)
      if (state.end > endTarget + extraRenderElements)
        state.end = Math.max(0, endTarget + extraRenderElements)
      else if (state.end < endTarget)
        state.end = Math.min(props.list.length, endTarget)
    }

    const onReportHeight = (index: number, height: number) => {
      const el = container.value
      if (!el) return

      if (height) {
        // new item mounted or updated
        itemHeights[index] = height
        historyHeights[index] = height
      }
      else {
        // item removed
        delete itemHeights[index]
      }

      // update estimations
      const { start, end } = state
      const avgTop = avgRange(historyHeights, 0, start)
      const avgContent = avgRange(itemHeights, start, end)
      const avgAll = avgRange(historyHeights, 0, props.list.length)
      const top = (avgTop || avgContent) * start
      const content = avgContent * (end - start)
      state.top = top
      state.height = avgAll * props.list.length
      state.content = content
      state.avg = avgTop || avgContent

      if (panel.value)
        panel.value.style.height = state.height + "px"

      // trigger a calculation since estimations get more precise
      onScroll()
    }

    const scrollToIndex = (index: number, smooth = true, lastEstimation = 0) => {
      const el = container.value
      if (!el) return

      // estimate the item location at index
      const { start, end } = state
      const avgContentHeight = avgRange(historyHeights, 0, index) || avgRange(historyHeights, start, end)
      // decide if to retry when last estimation is not accurate
      if (lastEstimation && Math.abs(avgContentHeight - lastEstimation) * index < avgContentHeight)
        return

      const { clientHeight, scrollTop } = el
      const maxScroll = avgContentHeight * index
      const minScroll = avgContentHeight * index - clientHeight + (historyHeights[index] || avgContentHeight)

      if (scrollTop <= maxScroll && scrollTop >= minScroll)
        return

      // decide scroll up or scroll down
      const half = avgContentHeight / 2
      const target = scrollTop > maxScroll ? maxScroll - half : minScroll + half
      el.scrollTo({
        top: target,
        behavior: smooth ? "smooth" : "auto",
      })
      // retry scroll after a small timeout to get more accurate result
      setTimeout(() => scrollToIndex(index, smooth, avgContentHeight), smooth ? 400 : 50)
    }

    expose({ el: container, $el: container, scrollToIndex })

    watchEffect(() => {
      if (props.list.length >= 0)
        nextTick(onScroll)
    })

    watchEffect(cleanup => {
      const el = container.value
      if (el) {
        const observer = new ResizeObserver(onScroll)
        observer.observe(el)
        cleanup(() => observer.unobserve(el))
      }
    })

    return () =>
      <div
        ref={container}
        class={["VirtualScroll overflow-y-auto", props.class]}
        onScroll={onScroll}>
        <div
          ref={panel}
          class="VirtualScrollPanel relative">
          <VirtualScrollList
            list={props.list}
            state={state}
            heights={historyHeights}
            keyProp={props.keyProp}
            renderComponent={props.renderComponent}
            reportHeight={onReportHeight}
          />
        </div>
      </div>
  },
})
