import { defineComponent, ref } from "vue"
import { tv } from "tailwind-variants"
import { CircularProgress } from "../Progress"
import { Ripple, useRipple } from "../Ripple"
import { Color } from "../theme"

const classes = tv({
  slots: {
    base: [
      "inline-flex items-center justify-center relative select-none align-middle box-border",
      "cursor-pointer disabled:cursor-auto disabled:pointer-events-none",
      "font-medium transition duration-200 overflow-hidden",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4",
      "focus-visible:outline-var-500/40 dark:focus-visible:outline-var-200/40",
      "[-webkit-tap-highlight-color:transparent]",
    ],
    loader: "",
    ripple: "",
  },
  variants: {
    variant: {
      text: {
        base: [
          "text-var-500 bg-transparent bg-var-500/[.08] hocus:bg-var-500/[.16]",
          "dark:text-var-200 dark:bg-var-400/[.08] dark:hocus:bg-var-400/[.16]",
        ],
        ripple: "bg-var-500 dark:bg-var-200/50",
      },
      contained: {
        base: [
          "text-white bg-var-600/90 hocus:bg-var-600 shadow-2 hocus:shadow-4 active:!shadow-8",
          "dark:text-black dark:bg-var-200/90 dark:hocus:bg-var-400",
        ],
        ripple: "bg-var-100 dark:bg-var-800/80",
      },
      outline: {
        base: [
          "text-var-500 border border-var-600/50 hocus:border-var-600/90 bg-transparent hocus:bg-var-500/[.04]",
          "dark:text-var-200 dark:border-var-200/50 dark:hocus:border-var-200 dark:hocus:bg-var-400/10",
        ],
        ripple: "bg-var-500 dark:bg-var-200/50",
      },
    },
    disabled: {
      true: "",
    },
    size: {
      small: {
        base: "rounded px-3 py-1 text-sm",
        loader: "mr-1.5 w-4 h-4",
      },
      normal: {
        base: "rounded px-4 py-1.5",
        loader: "mr-2 w-5 h-5",
      },
      icon: {
        base: "",
        loader: "",
      },
    },

  },
  compoundVariants: [
    {
      variant: "text", disabled: true,
      class: { base: "text-gray-400 bg-gray-500/10 dark:text-gray-500" },
    },
    {
      variant: "contained", disabled: true,
      class: { base: "text-gray-500 bg-gray-200 dark:text-gray-500 dark:bg-gray-700/70" },
    },
    {
      variant: "outline", disabled: true,
      class: { base: "text-gray-400 border border-gray-500/50 dark:text-gray-500" },
    },
  ],
})

export const Button = defineComponent({
  name: "Button",
  props: {
    class: null,
    color: {
      type: String as () => Color,
      default: "primary",
    },
    variant: {
      type: String as () => "text" | "contained" | "outline",
      default: "text",
    },
    size: {
      type: String as () => "normal" | "small" | "icon",
      default: "normal",
    },
    disabled: Boolean,
    loading: Boolean,
  },
  emits: {
    click: (e: MouseEvent) => true,
  },
  setup(props, { emit, expose, slots }) {
    const el = ref<HTMLButtonElement>()
    expose({ el, $el: el })

    const [ripple, rippleEvents] = useRipple()

    return () => {
      const { class: cls, color, variant, size, disabled: _disabled, loading } = props
      const disabled = _disabled || loading
      const { base, ripple: rippleClass, loader } = classes({ variant, size, disabled })
      return (
        <button
          ref={el}
          type="button"
          class={["Button", `color-${color}`, base({ class: cls })]}
          disabled={disabled}
          onClick={e => emit("click", e)}
          {...rippleEvents}
        >
          <Ripple ref={ripple} rippleClass={rippleClass()} />
          {loading &&
            <CircularProgress class={loader()} />}
          {slots.default?.()}
        </button>
      )
    }
  },
})
