
export const isOsx =
  /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh|iPhone|iPod|iPad)/i
    .test(navigator.userAgent)

export const modifiers = ["Alt", "Option", "Cmd", "Command", "Meta", "Ctrl", "Control", "Shift"]

export const osxKeyDisplay = {
  ctrl: "⌃",
  shift: "⇧",
  alt: "⌥",
  meta: "⌘",
  " ": "Space",
}

export const winKeyDisplay = {
  ctrl: "Ctrl",
  shift: "Shift",
  alt: "Alt",
  meta: "Win",
  " ": "Space",
}

export const keyDisplay = isOsx ? osxKeyDisplay : winKeyDisplay

export enum Keys {
  Space = " ",
  Enter = "Enter",
  Escape = "Escape",
  Backspace = "Backspace",
  Delete = "Delete",
  ArrowLeft = "ArrowLeft",
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowDown = "ArrowDown",
  Home = "Home",
  End = "End",
  PageUp = "PageUp",
  PageDown = "PageDown",
  Tab = "Tab",
}
