import { openDB } from "idb"
import { caniuse } from "@maine-cat/common/caniuse"
import { memo } from "@maine-cat/common/utils"

export interface KeyValueStore<Type = any> {
  get: <T = Type>(key: IDBValidKey) => Promise<T>
  set: <T = Type>(key: IDBValidKey, val: T) => Promise<IDBValidKey>
  delete: (key: IDBValidKey) => Promise<void>
  clear: () => Promise<void>
  keys: (range?: IDBKeyRange | IDBValidKey | null | undefined) => Promise<IDBValidKey[]>
}

export function createKeyValueStore<T = any>(name: string): KeyValueStore<T> {
  if (!caniuse("IndexedDB"))
    throw new TypeError("IndexedDB is not available")

  const getDb = memo(() => openDB("@maine-cat/" + name, 1, {
    upgrade(db) {
      db.createObjectStore(name)
    },
  }))

  return {
    async get<T = any>(key: IDBValidKey) {
      return (await getDb()).get(name, key) as Promise<T>
    },
    async set<T = any>(key: IDBValidKey, val: T) {
      return (await getDb()).put(name, val, key)
    },
    async delete(key: IDBValidKey) {
      return (await getDb()).delete(name, key)
    },
    async clear() {
      return (await getDb()).clear(name)
    },
    async keys(range?: IDBKeyRange | IDBValidKey | null | undefined) {
      return (await getDb()).getAllKeys(name, range)
    },
  }
}
