import { defineService } from "@maine-cat/common/service"
import { createKeyValueStore } from "@maine-cat/common/indexedDB"

export interface ConfigurationStore {
  get<T>(key: string): Promise<T | null>
  set<T>(key: string, value: T): Promise<void>
  delete(key: string): Promise<void>
  persisted(): Promise<boolean>
}

const storeName = "configuration-store"

export const useMockConfigurationStore = defineService({
  id: storeName,
  setup() {
    const store: ConfigurationStore = {
      async get() { return null },
      async set() { /** noop */ },
      async delete() { /** noop */ },
      async persisted() { return false },
    }
    return { store }
  },
})

export const useConfigurationStore = defineService({
  id: storeName,
  setup() {
    try {
      const objStore = createKeyValueStore(storeName)
      const store: ConfigurationStore = {
        async get(key) { return await objStore.get(key) },
        async set(key, value) { await objStore.set(key, value) },
        async delete(key) { await objStore.delete(key) },
        async persisted() { return await navigator.storage.persisted() },
      }
      return { store }
    }
    catch (error) {
      const store: ConfigurationStore = {
        async get() { return null },
        async set() { /** noop */ },
        async delete() { /** noop */ },
        async persisted() { return false },
      }
      return { store }
    }
  },
})
