import { ref } from "vue"
import { allFeatures } from "./features"

export interface FeatureEntry {
  id: string
  check: boolean
  required?: boolean
  message?: string
  externalURL?: string
}

type Features = (typeof allFeatures)[number]["id"]

function tryCheck(fn: () => boolean, key: string) {
  try {
    return fn()
  }
  catch (error) {
    console.error(`Error while checking "${key}"`)
    console.error(error)
    return false
  }
}

export const features: FeatureEntry[] = allFeatures.map(x => ({ ...x, check: tryCheck(x.check, x.id) }))

export function caniuse(id: Features) {
  const feature = features.find(x => x.id === id)
  if (!feature)
    throw new Error(`Requested feature "${id}" not found`)

  return feature.check
}

export const allRequiredSatisfied = ref(!import.meta.env.VITE_FORCE_UNSUPPORTED &&
  features.filter(x => x.required).every(x => x.check))
