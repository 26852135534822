import { Palette } from "./palette"
import * as color2k from "color2k"

function toRgbVar(color: string) {
  const [r, g, b] = color2k.parseToRgba(color)
  return `${r} ${g} ${b}`
}

export function createPaletteVariables(palette: Palette) {
  const style: Record<string, (readonly [string | number, string])[]> = {}

  const [h, s] = color2k.parseToHsla(palette.primary)

  const selection = `
  ::selection {
    background-color: ${color2k.transparentize(palette.primary, 0.5)};
  }`

  const range = [...Array(9).keys()].map(x => x * 100 + 100)

  style[":root"] = [
    [50, color2k.hsla(h, s / 10, 1 - 50 / 1000, 1)],
    ...range.map(x => [x, color2k.hsla(h, s / 10, 1 - x / 1000, 1)] as const),
    ...range.map(x => [`A${x}`, color2k.hsla(h, Math.sqrt(s / 10), 1 - x / 1000, 1)] as const),
  ]

  for (const color of Object.keys(palette) as (keyof typeof palette)[]) {
    const [h, s] = color2k.parseToHsla(palette[color])

    style[`.color-${color}`] = [
      [50, color2k.hsla(h, s, 1 - 50 / 1000, 1)],
      ...range.map(x => [x, color2k.hsla(h, s, 1 - x / 1000, 1)] as const),
      ...range.map(x => [`A${x}`, color2k.hsla(h, Math.sqrt(s), 1 - x / 1000, 1)] as const),
    ]
  }

  const colorVariables = Object.entries(style).map(([selector, styles]) => `
  ${selector} {
    ${styles.map(([name, value]) => `--color-${name}: ${toRgbVar(value)};`).join("\n")}
  }`).join("")

  return selection + colorVariables
}
