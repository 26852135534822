
export interface Palette {
  primary: string
  secondary: string
  success: string
  warn: string
  info: string
  error: string
}

export type Color = keyof Palette | "none"

export const defaultPalette: Palette = {
  primary: "#2196f3",
  secondary: "#f50057",
  success: "#4caf50",
  warn: "#ff9800",
  info: "#03a9f4",
  error: "#ef5350",
}
