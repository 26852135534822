import { defineComponent, unref } from "vue"
import { tv } from "tailwind-variants"
import { ListItem } from "../List"
import { useSelectContext } from "./context"

const classes = tv({
  base: "",
  variants: {
    state: {
      normal: "hocus:bg-gray-200/60 dark:hocus:bg-white/10",
      selected: "font-bold bg-var-400/10 dark:bg-var-300/20 hocus:bg-var-400/20 dark:hocus:bg-var-300/30",
      disabled: "cursor-auto text-gray-400",
    },
  },
})

export const SelectOption = defineComponent({
  name: "SelectOption",
  props: {
    value: null,
    class: null,
    disabled: Boolean,
    index: { type: Number, default: NaN },
  },
  setup(props, { slots }) {
    const { value, multiple } = useSelectContext()
    const onSelect = () => {
      if (unref(multiple)) {
        const arr = Array.from(value.value)
        const idx = arr.indexOf(props.value)
        if (idx < 0)
          arr.push(props.value)
        else
          arr.splice(idx, 1)
        value.value = arr
      }
      else {
        value.value = props.value
      }
    }
    return () => {
      const selected = unref(multiple)
        ? Array.from(value.value).includes(props.value)
        : value.value === props.value
      const state = props.disabled ? "disabled" : selected ? "selected" : "normal"

      return (
        <ListItem
          button={!props.disabled}
          class={["SelectOption", classes({ state, class: props.class })]}
          onClick={onSelect}
        >
          {slots.default?.({ selected })}
        </ListItem>
      )
    }
  },
})
