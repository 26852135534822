import { unref, watchEffect } from "vue"

export function useClickOutside(
  elRef: MaybeRef<HTMLElement | null | undefined>,
  listener: (e: PointerEvent) => void,
) {
  function _listener(e: PointerEvent) {
    const el = unref(elRef)
    if (!el || el === e.target || e.composedPath().includes(el))
      return
    listener(e)
  }

  watchEffect(cleanup => {
    window.addEventListener("pointerdown", _listener, { passive: true })
    cleanup(() => window.removeEventListener("pointerdown", _listener))
  })
}
