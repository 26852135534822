import { Teleport, defineComponent, ref, watch, watchEffect } from "vue"
import { tv } from "tailwind-variants"
import { FadeInOut, ScaleInOut } from "../Transitions"
import { Typography } from "../Typography"
import { useClickOutside } from "../hooks/useClickOutside"
import { useFocusTrap } from "../hooks/useFocusTrap"

const classes = tv({
  slots: {
    base: [
      "w-full max-w-md transform overflow-hidden rounded transition-all",
      "text-left align-middle shadow-8 bg-white dark:bg-var-700 pointer-events-auto",
    ],
    backdrop: "fixed inset-0 bg-black bg-opacity-25",
    layer: "fixed inset-0 overflow-y-auto pointer-events-none",
    container: "flex min-h-full items-center justify-center p-4",
  },
})

export const Dialog = defineComponent({
  name: "Dialog",
  props: {
    open: Boolean,
    noBackdrop: Boolean,
    class: null,
    containerClass: null,
    persistTimeout: { type: Number, default: 150 },
  },
  emits: {
    close: () => true,
  },
  setup(props, { emit, slots }) {

    const panelEl = ref<HTMLDivElement>()
    const close = () => emit("close")
    useClickOutside(panelEl, close)
    useFocusTrap(panelEl)

    const closing = ref(false)
    watch(() => props.open, (value, oldValue, cleanup) => {
      if (value === false && oldValue === true) {
        closing.value = true
        const timeout = setTimeout(() => closing.value = false, props.persistTimeout)
        cleanup(() => clearTimeout(timeout))
      }
      else {
        closing.value = false
      }
    }, { flush: "pre" })

    const onEscKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape")
        close()
    }

    watchEffect(cleanup => {
      if (props.open) {
        document.addEventListener("keydown", onEscKeydown)
        cleanup(() => document.removeEventListener("keydown", onEscKeydown))
      }
    })

    return () => {
      const { base, backdrop, layer, container } = classes()
      return (
        <Teleport to="body" disabled={!props.open && !closing.value}>
          <div class="Dialog relative z-100">
            {!props.noBackdrop &&
              <FadeInOut appear show={props.open}>
                <div class={["Backdrop", backdrop()]} />
              </FadeInOut>}

            <div class={["DialogLayer", layer()]}>
              <div class={["DialogScrollContainer", container({ class: props.containerClass })]}>
                <ScaleInOut appear show={props.open}>
                  <div ref={panelEl} class={["DialogPanel", base({ class: props.class })]}>
                    {slots.title && <Typography is="h3" class="m-6">
                      {slots.title()}
                    </Typography>}
                    {slots.text && <Typography silent class="m-6">
                      {slots.text()}
                    </Typography>}
                    {slots.content && <div class="m-6">
                      {slots.content()}
                    </div>}
                    {slots.default?.()}
                    {slots.actions && <div class="flex justify-end items-center space-x-2 mt-8 m-4">
                      {slots.actions()}
                    </div>}
                  </div>
                </ScaleInOut>
              </div>
            </div>
          </div>
        </Teleport>
      )
    }
  },
})
