import { Teleport, computed, defineComponent, ref } from "vue"
import { tv } from "tailwind-variants"
import { useFloating } from "../hooks/useFloating"
import { ScaleInOut } from "../Transitions"
import type { Placement, Strategy } from "@floating-ui/dom"

const classes = tv({
  slots: {
    base: "relative",
    tooltip: "absolute z-10 text-sm px-2 py-1 bg-gray-600 text-gray-100 rounded shadow-1",
    arrow: "absolute w-4 h-4 rotate-45 bg-gray-600 z-[-1] pointer-events-none",
  },
})

export const Tooltip = defineComponent({
  name: "Tooltip",
  props: {
    class: null,
    tooltipClass: null,
    offset: { type: Number, default: 6 },
    focusTimeout: { type: Number, default: 150 },
    blurTimeout: { type: Number, default: 150 },
    placement: { type: String as () => Placement, default: "top" },
    onClick: null,
    strategy: String as () => Strategy,
  },
  setup(props, { slots }) {
    const show = ref(false)

    const arrow = ref<HTMLDivElement>()
    const reference = ref<HTMLDivElement>()

    useFloating(computed(() => arrow.value?.parentElement!), {
      arrow,
      reference,
      offset: props.offset,
      placement: props.placement,
      strategy: props.strategy,
    })

    let timeout: any
    const onFocus = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => show.value = true, props.focusTimeout)
    }
    const onBlur = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => show.value = false, props.blurTimeout)
    }
    // detect tab keyup instead of focusin
    const onTabKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Tab")
        onFocus()
    }

    const onTooltipClick = (e: MouseEvent) => {
      e.stopPropagation()
    }

    return () => {
      const { base, tooltip, arrow: arrowClass } = classes()
      const content = () =>
        <ScaleInOut show={show.value}>
          <div role="tooltip" class={tooltip({ class: props.tooltipClass })} onClick={onTooltipClick}>
            <div ref={arrow} class={arrowClass()}></div>
            {slots.tip?.()}
          </div>
        </ScaleInOut>

      return (
        <div
          class={["Tooltip", base({ class: props.class })]}
          onPointerenter={onFocus}
          onPointerleave={onBlur}
          onFocusout={onBlur}
          onKeyup={onTabKeyUp}
          onClick={props.onClick}
          ref={reference}
        >
          {slots.default?.()}
          {props.strategy === "fixed"
            ? <Teleport to={document.body}>{content()}</Teleport>
            : content()}
        </div>
      )
    }
  },
})
