import { defineTransition } from "./defineTransition"

export const FadeInOut = defineTransition("FadeInOut", {
  enter: "duration-100 ease-out",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "duration-75 ease-in",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
})
