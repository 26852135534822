import { defineTransition } from "./defineTransition"

export const Collapse = defineTransition("Collapse", {
  enter: "transition-all duration-150 overflow-hidden ease-in-out",
  enterFrom: "opacity-0 !h-0",
  enterTo: "opacity-100",
  leave: "transition-all duration-150 overflow-hidden ease-in-out",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0 !h-0",
})
