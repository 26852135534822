
export const allFeatures = [
  {
    id: "IndexedDB" as const,
    check: () => typeof window.indexedDB !== "undefined" &&
      typeof window.IDBKeyRange.lowerBound !== "undefined",
    required: true,
    externalURL: "https://caniuse.com/?search=indexeddb%202.0",
  },
  {
    id: "BroadcastChannel" as const,
    check: () => typeof window.BroadcastChannel !== "undefined",
    required: true,
    externalURL: "https://caniuse.com/?search=broadcastchannel",
  },
  {
    id: "FileSystemAccess" as const,
    check: () => typeof window.showDirectoryPicker !== "undefined",
    externalURL: "https://caniuse.com/?search=file%20system%20access",
  },
  {
    id: "Intl.Segmenter" as const,
    check: () => typeof Intl.Segmenter !== "undefined",
    externalURL: "https://caniuse.com/?search=intl%20segmenter",
  },
]
