import { Fragment, Slot, VNode, h, renderSlot } from "vue"

function renderChildren(children?: string | VNode[] | {} | null) {
  if (!children)
    return null
  if (typeof children === "string")
    return children
  if (Array.isArray(children))
    return children
  return renderSlot(children, "default")
}

export function resolveContent(
  value: any,
  multiple: boolean,
  renderFunction: Slot | undefined,
  options: VNode[],
  showPlaceholder: boolean,
  placeholder: any,
) {
  const renderResults = renderFunction?.({ selected: value })
  if (renderResults)
    return renderResults

  const results: any[] = []

  if (multiple) {
    if (!Array.isArray(value))
      throw new Error("<Select multiple> must have array value.")

    const items = value.map(v => options.find(x => x.props?.value === v)).filter(Boolean) as VNode[]

    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (i !== 0)
          results.push(", ")
        results.push(renderChildren(items[i].children))
      }
    }
    else {
      results.push(showPlaceholder ? placeholder : "\u200B")
    }
  }
  else {
    const item = options.find(x => x.props?.value === value)

    if (item?.children)
      results.push(renderChildren(item.children))
    else
      results.push(showPlaceholder ? placeholder : "\u200B")
  }

  return h(Fragment, null, results)
}
