import { defineComponent } from "vue"
import { tv } from "tailwind-variants"
import css from "./index.module.css"

const circular = tv({
  slots: {
    base: "inline-block",
    svg: ["block", css.rotate],
    circle: ["stroke-current", css.circleDash],
  },
})

const linear = tv({
  slots: {
    base: "relative h-1",
    container: "absolute inset-0 bg-var-A200/30 overflow-hidden",
    bar: ["absolute left-0 h-full bg-var-500 transition-all overflow-hidden", css.linearDash],
  },
})

export const CircularProgress = defineComponent({
  name: "CircularProgress",
  props: {
    class: null,
    thickness: { type: Number, default: 4 },
  },
  setup(props) {
    const SIZE = 48
    return () => {
      const { base, svg, circle } = circular()
      return (
        <span
          class={["CircularProgress", base({ class: props.class })]}
          role="progressbar">
          <svg
            class={svg()}
            viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
            <circle
              class={circle()}
              fill="none"
              cx={SIZE} cy={SIZE}
              r={(SIZE - props.thickness) / 2}
              stroke-width={props.thickness} />
          </svg>
        </span>
      )
    }
  },
})

export const LinearProgress = defineComponent({
  name: "LinearProgress",
  props: {
    class: null,
    barClass: null,
    value: Number, // from 0 to 1
  },
  setup(props) {
    return () => {
      const { base, container, bar } = linear()
      return (
        <div class={["LinearProgress", base({ class: props.class })]} role="progressbar">
          <div class={container()}>
            <span
              class={bar({ class: props.barClass })}
              style={{ width: `${(props.value || 1) * 100}%` }} />
          </div>
        </div>
      )
    }
  },
})
