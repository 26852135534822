import { isOsx, modifiers, osxKeyDisplay, winKeyDisplay } from "./constants"
import { Hotkey } from "./types"

/**
 * Parse a hotkey from a JSON string or an array of keys
 * '["ctrl", "s"]' or ["ctrl", "s"]
 */
export function hotkeyFromString(hotkey: string | string[]) {
  const keys = Array.isArray(hotkey)
    ? hotkey
    : JSON.parse(hotkey) as string[]

  const key: Hotkey = { key: "" }
  for (const k of keys) {
    if (!k) continue
    const lower = k.toLowerCase()
    if (lower === "ctrl") key.ctrl = true
    else if (lower === "alt") key.alt = true
    else if (lower === "shift") key.shift = true
    else if (lower === "meta") key.meta = true
    else if (!key.key) key.key = k
  }
  return key
}

/**
 * Stringify a hotkey in normalized form
 */
export function hotkeyToString(key: Hotkey, ctrlToCommand = false) {
  const keys: string[] = []
  if (key.ctrl && !ctrlToCommand) keys.push("ctrl")
  if ((key.ctrl && ctrlToCommand) || key.meta) keys.push("meta")
  if (key.alt) keys.push("alt")
  if (key.shift) keys.push("shift")
  keys.push(key.key.length <= 1 ? key.key.toUpperCase() : key.key)
  return JSON.stringify(keys)
}

/**
 * Normalize a hotkey string
 */
export function normalizeHotkey(hotkey: string | string[], ctrlToCommand = isOsx) {
  return hotkeyToString(hotkeyFromString(hotkey), ctrlToCommand)
}

/**
 * Get a hotkey string from event
 */
export function hotkeyFromEvent(e: KeyboardEvent, omitModifiers = true) {
  const key = e.key.length <= 1 ? e.key.toUpperCase() : e.key
  const isModifier = modifiers.includes(key)
  if (omitModifiers && isModifier)
    return null
  const hotkey: Hotkey = { key: isModifier ? "" : key }
  if (e.ctrlKey) hotkey.ctrl = true
  if (e.altKey) hotkey.alt = true
  if (e.shiftKey) hotkey.shift = true
  if (e.metaKey) hotkey.meta = true
  return hotkeyToString(hotkey)
}

/**
 * Display a hotkey
 */
export function hotkeyToDisplay(hotkey: string | string[], osx?: boolean): string
export function hotkeyToDisplay(hotkey?: string | string[] | null, osx?: boolean): undefined | string
export function hotkeyToDisplay(hotkey?: string | string[] | null, osx?: boolean) {
  if (!hotkey)
    return undefined
  const key = hotkeyFromString(hotkey)
  const keyDisplay = osx ?? isOsx ? osxKeyDisplay : winKeyDisplay
  const display = (key: string) => key in keyDisplay ? keyDisplay[key as " "] : key
  let result = ""
  if (key.ctrl)
    result += display("ctrl") + "+"
  if (key.alt)
    result += display("alt") + "+"
  if (key.shift)
    result += display("shift") + "+"
  if (key.meta)
    result += display("meta") + "+"
  result += display(key.key)
  return result
}
