import { allIndicesOf } from "@maine-cat/common/utils"

export interface HighlightRule {
  token: string
  hoverMessage?: string
  decoration?: string
  rulerDecoration?: string
}

export interface HighlightRuleSet {
  owner: string
  rules: HighlightRule[]
}

export interface RangeHighlight {
  text: string
  rule?: HighlightRule
}

export function resolveHighlight(input: string, rules: HighlightRuleSet) {
  // assume `rules` do not overlap
  let current: RangeHighlight[] = [{ text: input }]
  for (const rule of rules.rules) {
    current = current.flatMap(x => {
      // ignore ruled span
      if (x.rule) return [x]
      const ranges: RangeHighlight[] = []
      let last = 0
      for (const i of allIndicesOf(x.text, rule.token)) {
        if (last < i)
          ranges.push({ text: x.text.substring(last, i) })
        else if (i < last)
          continue
        last = i + rule.token.length
        ranges.push({ text: x.text.substring(i, last), rule })
      }
      if (last < x.text.length)
        ranges.push({ text: x.text.substring(last) })
      return ranges
    })
  }
  return current
}
