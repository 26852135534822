import { defineComponent } from "vue"
import { Color } from "../theme"
import { tv } from "tailwind-variants"

const classes = tv({
  base: "ExternalLink transition text-var-A600 dark:text-var-A300 hover:underline after:content-['_↗']",
})

export const ExternalLink = defineComponent({
  name: "ExternalLink",
  props: {
    class: null,
    href: String,
    color: {
      type: String as () => Color,
      default: "primary",
    },
  },
  setup(props, { slots }) {
    return () =>
      <a
        class={["Link", `color-${props.color}`, classes({ class: props.class })]}
        href={props.href} target="_blank">
        {slots.default?.()}
      </a>
  },
})
