import { createInjectionState } from "@maine-cat/common/utils"
import { Ref } from "vue"

const injectKey = Symbol("select-context")

export const [provideSelectContext, useSelectContext] =
  createInjectionState(
    injectKey,
    (value: Ref<any>, multiple: MaybeRef<boolean>) =>
      ({ value, multiple })
  )
