import { App, EffectScope, effectScope, getCurrentInstance, inject, isReactive, isRef, watchEffect } from "vue"

export interface ServiceDescriptor<T> {
  /**
   * Unique id
   */
  id: string
  /**
   * Scoped setup function, will be called only once
   */
  setup(): NonNullable<T>
}

const disposesKey = "service.app-scope"

const setupKey = "service.setup"

function getAppScope(app: App) {
  let scope = inject<EffectScope | undefined>(disposesKey, undefined)
  if (!scope) {
    const _scope = scope = effectScope(true)
    app.provide(disposesKey, _scope)

    // hook the original unmount function on app
    const originalUnmount = app.unmount
    app.unmount = () => {
      // unmount and then cleanup scope
      app.unmount = originalUnmount
      app.unmount()
      _scope.stop()
      delete app._context.provides[disposesKey]
    }
  }
  return scope
}

export function defineService<T>({ id, setup }: ServiceDescriptor<T>) {

  function setupService(): NonNullable<T> {
    // inject service setup result to app root provider
    const instance = getCurrentInstance()
    if (!instance)
      throw new Error("Unable to setup service outside Vue component setup context")

    const parentValue = inject<T | undefined>(id, undefined)
    if (parentValue) {
      if (!(parentValue as any)[setupKey])
        console.warn(`Partial initialized service "${id}" detected. Try avoid circular dependency.`)
      return parentValue
    }

    // IMPORTANT!
    // make sure to create a detached scope to setup the service
    // so the effects won't be cleaned up when instance unmounts
    const scope = getAppScope(instance.appContext.app)

    // allow circular service reference only if not destructed
    const serviceRoot: NonNullable<T> = {} as any

    scope.run(() => {
      watchEffect(cleanup => {
        // inject to app root provider
        instance.appContext.app.provide(id, serviceRoot)
        cleanup(() => {
          delete instance.appContext.provides[id]
        })
      })
    })

    const setupValue = scope.run(setup)
    if (!setupValue)
      throw new Error(`Cannot return nullable values in service "${id}" setup`)

    if (Object.getPrototypeOf(setupValue) !== Object.prototype)
      console.warn(`Service "${id}" is setup with a non-plain object. The prototype will be ignored`)

    if (isReactive(setupValue) || isRef(setupValue))
      console.warn(`Service "${id}" is setup with a non-plain object. The reactive state will be ignored`)

    Object.assign(serviceRoot, { [setupKey]: true }, setupValue)

    return serviceRoot
  }

  function injectService() {
    // just inject
    let value = inject<T | undefined>(id, undefined)
    if (!value)
      value = setupService()

    return value as NonNullable<T>
  }

  return injectService
}
