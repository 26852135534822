import { defineComponent, withModifiers } from "vue"
import { tv } from "tailwind-variants"
import { Color } from "../theme"
import { useUncontrolled } from "../hooks/useUncontrolled"
import { Ripple, useRipple } from "../Ripple"
import { CheckboxBlankIcon, CheckboxCheckedIcon } from "./icons"

const classes = tv({
  slots: {
    base: "inline-flex items-center group",
    box: [
      "inline-block relative focus-visible:outline-none rounded",
      "p-2 rounded-full overflow-hidden transition duration-200",
      "[-webkit-tap-highlight-color:transparent]",
    ],
    icon: "transition duration-200",
    label: "grow",
  },
  variants: {
    size: {
      normal: { icon: "w-6 h-6" },
      small: { icon: "w-5 h-5" },
    },
    disabled: {
      true: {
        box: "pointer-events-none",
        label: "text-gray-400 dark:text-gray-600",
      },
      false: {
        base: "cursor-pointer",
      },
    },
    labelPlacement: {
      top: {
        base: "flex-col-reverse",
      },
      left: {
        base: "flex-row-reverse",
      },
      right: {
        base: "flex-row",
      },
      bottom: {
        base: "flex-col",
      },
    },
    checked: {
      true: {},
    },
    fullWidth: {
      true: { base: "w-full" },
    },
  },
  compoundVariants: [
    {
      disabled: false, checked: false,
      class: {
        box: [
          "group-hover:bg-gray-500/20 focus-visible:bg-gray-500/20",
          "dark:group-hover:bg-gray-200/20 dark:focus-visible:bg-gray-200/20",
        ],
        icon: "text-gray-600 dark:text-gray-400",
      },
    },
    {
      disabled: false, checked: true,
      class: {
        box: "group-hover:bg-var-300/30 focus-visible:bg-var-300/30",
        icon: "text-var-600 dark:text-var-400",
      },
    },
    {
      disabled: true, checked: false,
      class: { icon: "text-gray-200 dark:text-gray-600" },
    },
    {
      disabled: true, checked: true,
      class: { icon: "text-var-200 dark:text-var-800" },
    },
  ],
})

export const Checkbox = defineComponent({
  name: "Checkbox",
  props: {
    class: null,
    modelValue: null,
    defaultValue: Boolean,
    color: {
      type: String as () => Color,
      default: "primary",
    },
    size: {
      type: String as () => "normal" | "small",
      default: "normal",
    },
    label: String,
    labelPlacement: {
      type: String as () => "left" | "right" | "top" | "bottom",
      default: "right",
    },
    disabled: Boolean,
    fullWidth: Boolean,
  },
  emits: {
    "update:modelValue": (e: boolean) => true,
  },
  setup(props, { slots, emit }) {
    const value = useUncontrolled(
      props.defaultValue, () => props.modelValue,
      value => emit("update:modelValue", value),
    )

    const [ripple, rippleEvents] = useRipple()

    const onClick = withModifiers((e: MouseEvent) => {
      value.value = !value.value
    }, ["stop", "prevent"])

    return () => {
      const checked = value.value
      const { labelPlacement, size, fullWidth, disabled, label, color, class: cls } = props
      const {
        base, icon, box, label: labelClass,
      } = classes({ disabled, checked, labelPlacement, size, fullWidth })

      return (
        <label class={["Checkbox", `color-${color}`, base({ class: cls })]}>
          <button
            disabled={disabled}
            class={box()}
            {...rippleEvents}
            onClick={onClick}
          >
            <Ripple ref={ripple} center />
            {value.value
              ? <CheckboxCheckedIcon class={icon()} />
              : <CheckboxBlankIcon class={icon()} />}
          </button>
          <span class={labelClass()}>
            {slots.default?.() ?? label}
          </span>
        </label>
      )
    }
  },
})
