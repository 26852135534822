import { computed, defineComponent, ref, watchEffect } from "vue"
import { tv } from "tailwind-variants"
import { useUncontrolled } from "../hooks/useUncontrolled"

function limit(v: number, min: number, max: number) {
  return v < min ? min : v > max ? max : v
}

const classes = tv({
  slots: {
    base: "flex items-stretch",
    bar: "z-10 color-primary hover:bg-var-300 dark:hover:bg-var-600 transition duration-150 delay-200",
    activeBar: "bg-var-300 dark:bg-var-600",
    body: "",
    panel: "relative",
    panel2: "relative",
  },
  variants: {
    direction: {
      horizontal: {
        base: "flex-row",
        bar: "w-1 h-full -mx-[2px] cursor-ew-resize",
        body: "cursor-ew-resize",
      },
      vertical: {
        base: "flex-col",
        bar: "h-1 w-full -my-[2px] cursor-ns-resize",
        body: "cursor-ns-resize",
      },
    },
    border: {
      true: {},
    },
  },
  compoundVariants: [
    {
      border: true, direction: "horizontal",
      class: {
        panel2: "border-l border-gray-300 dark:border-gray-600",
      },
    },
    {
      border: true, direction: "vertical",
      class: {
        panel2: "border-t border-gray-300 dark:border-gray-600",
      },
    },
  ],
})

export const Splitter = defineComponent({
  name: "Splitter",
  props: {
    modelValue: null,
    defaultValue: Number,
    class: null,
    panelClass: null,
    border: Boolean,
    direction: {
      type: String as () => "horizontal" | "vertical",
      default: "horizontal",
    },
    bias: {
      type: String as () => "before" | "after",
      default: "before",
    },
    measure: {
      type: String as () => "%" | "px",
      default: "%",
    },
    limit: {
      type: Array as any as () => [number, number],
      default: [20, 80],
    },
  },
  emits: {
    "update:modelValue": (e: number) => true,
  },
  setup(props, { slots, emit }) {
    const value = useUncontrolled(
      props.defaultValue ?? props.limit[0], () => props.modelValue,
      v => emit("update:modelValue", v)
    )
    const limited = computed(() => limit(value.value, ...props.limit))

    const containerEl = ref<HTMLDivElement>()
    const barEl = ref<HTMLDivElement>()
    const beforeEl = ref<HTMLDivElement>()
    const afterEl = ref<HTMLDivElement>()

    watchEffect(() => {
      let before = beforeEl.value, after = afterEl.value
      if (!before || !after)
        return

      if (props.bias !== "before")
        [before, after] = [after, before]

      before.style.flex = `0 0 ${limited.value}${props.measure}`
      after.style.flex = "1 1 0"
    }, {})

    function handlePointerMove(e: PointerEvent) {
      e.preventDefault()
      e.stopPropagation()

      const el = containerEl.value
      if (!el)
        return
      const rect = el.getBoundingClientRect()

      let [current, total] = props.direction === "horizontal"
        ? [e.clientX - rect.left, rect.width]
        : [e.clientY - rect.top, rect.height]

      if (props.measure === "%") {
        current = current / total * 100
        total = 100
      }

      if (props.bias === "after")
        current = total - current

      value.value = limit(current, ...props.limit)
    }

    function startDrag(e: MouseEvent | TouchEvent) {
      e.stopPropagation()
      e.preventDefault()
      const el = barEl.value
      if (!el)
        return

      const { activeBar, body } = classes({ direction: props.direction })
      const barClasses = activeBar().split(/\s+/g)
      const bodyClasses = body().split(/\s+/g)

      function cleanup(e: PointerEvent) {
        el!.classList.remove(...barClasses)
        document.body.classList.remove(...bodyClasses)
        window.removeEventListener("pointermove", handlePointerMove)
      }

      el.classList.add(...barClasses)
      document.body.classList.add(...bodyClasses)
      // use pointer events here
      window.addEventListener("pointermove", handlePointerMove)
      document.addEventListener("pointerup", cleanup, { once: true })
      document.addEventListener("pointerleave", cleanup, { once: true })
    }

    return () => {
      const { base, bar, panel, panel2 } = classes({
        border: props.border, direction: props.direction,
      })
      return (
        <div
          ref={containerEl}
          class={["Splitter", base({ class: props.class })]}
        >
          <div
            ref={beforeEl}
            class={panel({ class: props.panelClass })}
          >
            {slots.before?.()}
          </div>
          <div
            ref={barEl}
            class={["SplitterBar", bar()]}
            onMousedown={startDrag}
            onTouchstart={startDrag}
          />
          <div
            ref={afterEl}
            class={panel2({ class: props.panelClass })}
          >
            {slots.after?.()}
          </div>
        </div>
      )
    }
  },
})
